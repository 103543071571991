import React, {useEffect, useMemo, useState} from "react";
import UnderHeader from "src/components/underHeader/underHeader";
import {rateStyle} from "./rate-styles";
import RateTable from "./rateTable/rateTable";
import GlobalRateTable from "./rateTable/globalRateTable";
import CChart from "src/components/chart/chart";
import moment from "moment";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import {Axios} from "src/api/api";
import CSwitch from "src/cors/switch/cSwitch";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import CSelect from "src/cors/select/cSelect";
import CInput from "src/cors/input/cInput";
import {useNavigate} from "react-router-dom";

let goldData = {
    1: {name: "999 ֊ 24k", order: 1},
    2: {name: "995 ֊ 24k", order: 2},
    3: {name: "958 ֊ 23k", order: 4},
    4: {name: "916 ֊ 22k", order: 5},
    5: {name: "900 ֊ 21.6k", order: 6},
    6: {name: "875 ֊ 21k", order: 7},
    7: {name: "750 ֊ 18k", order: 7},
    8: {name: "585 ֊ 14k", order: 3},
    9: {name: "500 ֊ 12k", order: 9},
    10: {name: "416 ֊ 10k", order: 10},
    11: {name: "375 ֊ 9k", order: 11},
    12: {name: "333 ֊ 8k", order: 12},
    13: {name: "999", order: 13},
    14: {name: "995", order: 14}
}


const Rate = () => {
    const classes = rateStyle();
    const t = useTranslation()
    const navigation = useNavigate()
    const [dataGold, setDataGold] = useState([])
    const [dataSilver, setDataSilver] = useState([])
    const [dataGlobal, setDataGlobal] = useState([])
    const [status, setStatus] = useState(true)
    const [coefficientStatus, setCoefficientStatus] = useState(true)
    const [coefficients, setCoefficients] = useState([])
    const [coefficientsMetal, setCoefficientsMetal] = useState(1)
    let global = [
        {name: t('metals.gold'), id: 1},
        {name: t('metals.silver'), id: 2},
        {name: t('metals.platinum'), id: 3},
        {name: t('metals.palladium'), id: 4},
    ]

    const [rubValue, setRubValue] = useState("");
    const [globalGoldValue, setGlobalGoldValue] = useState("");
    const [globalSilverValue, setGlobalSilverValue] = useState("");

    const handleEditGold = (e, index, type) => {
        if (isNaN(+e)) {
            return
        }
        let arr = [...dataGold]
        let newData = arr[index]
        let s999 = arr[0]
        let b995 = arr[1]
        let b958 = arr[3]
        let b916 = arr[4]
        let b900 = arr[5]
        let b875 = arr[6]
        let b750 = arr[7]
        let b500 = arr[8]
        let b416 = arr[9]
        let b375 = arr[10]
        let b333 = arr[11]
        if (type === 'buy') {
            if (newData.name === '585 ֊ 14k') {
                b500.buy = +parseFloat(e * 0.843).toFixed(2)
                b333.buy = +parseFloat((e - e * 0.015) * 0.569).toFixed(2)
                b375.buy = +parseFloat((e - e * 0.015) * 0.64).toFixed(2)
                b416.buy = +parseFloat((e - e * 0.015) * 0.71).toFixed(2)
                b750.buy = +parseFloat(e * 1.28).toFixed(2)
                b875.buy = +parseFloat(e * 1.5).toFixed(2)
                b916.buy = +parseFloat(e * 1.57).toFixed(2)
                b958.buy = +parseFloat(e * 1.64).toFixed(2)
                b900.buy = +parseFloat(e * 1.54).toFixed(2)
            }
        }
        if (type === 'sell') {
            if (newData.name === '999 ֊ 24k') {
                s999.buy = +parseFloat(e - 0.6).toFixed(2)
                b995.buy = +parseFloat(e - 0.9).toFixed(2)
                b995.sell = +parseFloat(e - 0.3).toFixed(2)
            }
            if (newData.name === '585 ֊ 14k') {
                b333.sell = +parseFloat((e - e * 0.015) * 0.569).toFixed(2)
                b375.sell = +parseFloat((e - e * 0.015) * 0.64).toFixed(2)
                b416.sell = +parseFloat((e - e * 0.015) * 0.71).toFixed(2)
                b500.sell = +parseFloat(e * 0.843).toFixed(2)
                b750.sell = +parseFloat(e * 1.28).toFixed(2)
                b875.sell = +parseFloat(e * 1.5).toFixed(2)
                b916.sell = +parseFloat(e * 1.57).toFixed(2)
                b958.sell = +parseFloat(e * 1.64).toFixed(2)
                b900.sell = +parseFloat(e * 1.54).toFixed(2)
            }
        }
        newData[type] = e
        arr[0] = s999
        arr[1] = b995
        arr[3] = b958
        arr[4] = b916
        arr[5] = b900
        arr[6] = b875
        arr[7] = b750
        arr[8] = b500
        arr[9] = b416
        arr[10] = b375
        arr[11] = b333
        arr[index] = newData
        setDataGold(arr)
    }


    const handleEditSilver = (e, index, type) => {
        if (isNaN(+e)) {
            return
        }
        let arr = [...dataSilver]
        let newData = arr[index]
        newData[type] = +e
        arr[index] = newData
        setDataSilver(arr)
    }

    const requestGet = () => {
        Axios.get(`rate/local`).then(res => {
            if (res.data.status === 'open') {
                setStatus(true)
            } else {
                setStatus(false)
            }
            let arr = []
            let arrSilver = []
            res?.data?.data.forEach((item, index) => {
                if (index < 12) {
                    arr.push({...item, ...goldData[item.id]})
                } else {
                    arrSilver.push({...item, ...goldData[item.id]})
                }
            })
            arr.sort((a, b) => a.order - b.order)
            setDataGold(arr)
            setDataSilver(arrSilver)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'errr ')
        })
    }

    const handleCancel = () => {
        requestGet()
    }

    const handleSave = () => {
        let golds = dataGold.map(item => {
            return {
                ...item,
                buy: +item.buy,
                sell: +item.sell
            }
        })
        let silvers = dataSilver.map(item => {
            return {
                ...item,
                buy: +item.buy,
                sell: +item.sell
            }
        })
        Axios.put(`rate/local_set`, [...golds, ...silvers]).then(() => {
            requestGet()
            toast('saved')
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }
    const changeStatus = () => {
        Axios.put(`rate/local_status`, {status: status ? 2 : 1}).then(res => {
            setStatus(!status)
            toast('saved')
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })

    }
    const changeCoefficientStatus = () => {
        setCoefficientStatus(!coefficientStatus)
        Axios.put(`rate/local_auto_update`, {state: !coefficientStatus}).then(res => {
        })
    }

    const handleCoefficients = () => {
        Axios.put('rate/local_coefficients', coefficients).then(res => {
            requestGet()
            toast('saved')
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }
    const handleCoefficientMetal = (e) => {
        setCoefficientsMetal(+e.value)
    }

    const handleCoefficientValues = (e, keyName) => {
        let arr = coefficients
        let newValues = {...arr[coefficientsMetal]}
        newValues[keyName] = e.value
        arr[coefficientsMetal] = newValues
        setCoefficients([...arr])
    }


    const handleRubValueChange = (e) => {
        setRubValue(e.target.value);
    };
    const handleGlobalGoldValueChange = (e) => {
        setGlobalGoldValue(e.target.value);
    };
    const handleGlobalSilverValueChange = (e) => {
        setGlobalSilverValue(e.target.value);
    };

    const handleUpdateRubValue = () => {
        Axios.put("user/update_rub_value", { rub_value : parseFloat(rubValue) })
            .then(() => {
                toast("Ruble value updated!");
            })
            .catch((err) => {
                toast(errorMessages(err?.response?.data?.code));
            });
    };


    const handleUpdateGlobalValue = () => {
        Axios.put('rate/global_coefficients', [{global_gold : parseFloat(globalGoldValue),global_silver : parseFloat(globalSilverValue)}]).then(res => {
            requestGet()
            toast('Saved-')
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }

    const renderChart = useMemo(() => {
        return <CChart/>
    }, [])

    useEffect(() => {
        Axios('rate/local_auto_update').then(res => {
            setCoefficientStatus(res.data.state)
        })
    }, [])

    useEffect(() => {
        Axios('rate/local_coefficients').then(res => {
            setCoefficients(res.data)
        })
    }, [])
    useEffect(() => {
        Axios('rate/global_coefficients').then(res => {
            if(res && res.data && res.data[0]){
                setGlobalGoldValue(res.data[0].global_gold ? res.data[0].global_gold : 0);
                setGlobalSilverValue(res.data[0].global_silver ? res.data[0].global_silver : 0)
            }

        })
    }, [])

    useEffect(() => {
        requestGet()
    }, []);

    useEffect(() => {
        Axios.get(`rate/global`).then(res => {
            let arr = []
            res?.data.forEach((item, index) => {
                arr.push({...item, name: global[index]?.name})
            })
            setDataGlobal(arr)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, []);

    useEffect(() => {
        Axios.get("user/get_rub_value")
            .then((res) => {
                setRubValue(res.data?.rub_value || "");
            })
            .catch((err) => {
                toast(errorMessages(err?.response?.data?.code));
            });
    }, []);
    return (
        <div className={classes.rateContainer}>
            <UnderHeader title={t("header.rate")} context={<div className={classes.switchBox}>
                <p className={classes.switchBoxClose}>{t("seller_rate.close")}</p>
                <CSwitch onChange={changeStatus} checked={status}/>
                <p className={classes.switchBoxOpen}>{t("seller_rate.open")}</p>
            </div>}
            />
            <div className={classes.coefficientsSwitch}>
                <CSwitch onChange={changeCoefficientStatus} checked={coefficientStatus}/>
            </div>
            <div className={classes.coefficients}>
                <div className={classes.coefficientsBox}>
                    <CSelect
                        defaultValue={[{label: t('metals.gold'), value: 1}]}
                        onChange={(e) => handleCoefficientMetal(e)}
                        label={t('metals.metal')}
                        options={[{label: t('metals.gold'), value: 1}, {label: t('metals.silver'), value: 0}]}
                    />
                    <CInput
                        value={coefficients[coefficientsMetal]?.buy_585}
                        onChange={(e) => handleCoefficientValues(e.target, 'buy_585')}
                        label={`${t(`seller_rate.buy`)} 585`}
                    />
                    <CInput
                        value={coefficients[coefficientsMetal]?.sell_585}
                        onChange={(e) => handleCoefficientValues(e.target, 'sell_585')}
                        label={`${t(`seller_rate.sell`)} 585`}
                    />
                </div>
                <div className={classes.coefficientsBox}>
                    <CInput
                        value={coefficients[coefficientsMetal]?.global_to_999}
                        onChange={(e) => handleCoefficientValues(e.target, 'global_to_999')}
                        label={`${t(`seller_rate.global_to`)} 999`}
                    />
                    <CInput
                        value={coefficients[coefficientsMetal]?.rate_995}
                        onChange={(e) => handleCoefficientValues(e.target, 'rate_995')}
                        label={`${t(`seller_rate.rate`)} 995`}
                    />
                    <CInput
                        value={coefficients[coefficientsMetal]?.buy_999}
                        onChange={(e) => handleCoefficientValues(e.target, 'buy_999')}
                        label={`${t(`seller_rate.buy`)} 999`}
                    />
                </div>

            </div>
            <div className={classes.coefficientsBtn}>
                <CButton onPress={handleCoefficients} backgroundColor={colors.blue} title={t('project.save')}/>
            </div>
            <div className={classes.coefficientsBtnRate}>
                <CInput
                    value={rubValue}
                    onChange={handleRubValueChange}
                    label={`RUB %`}
                />
                <CButton onPress={handleUpdateRubValue} backgroundColor={colors.blue} title={t('project.save')}/>
            </div>
            <div className={classes.coefficientsBtnRate}>
                <CInput
                    value={globalGoldValue}
                    onChange={handleGlobalGoldValueChange}
                    label={`Global Gold`}
                />
                <CInput
                    value={globalSilverValue}
                    onChange={handleGlobalSilverValueChange}
                    label={`Global Silver`}
                />
                <CButton onPress={handleUpdateGlobalValue} backgroundColor={colors.blue} title={t('project.save')}/>
            </div>

            <div className={classes.rateTableContent}>
                <div className={classes.rateTableWrapper}>
                    <div className={classes.flexContainer}>
                        <p className={classes.rateTableTitle}>{t("seller_rate.local_price")}</p>
                        <p className={classes.rateTableTitle}>{moment().format('L')}</p>
                    </div>
                    <RateTable
                        handleEdit={handleEditGold}
                        dataBody={dataGold}
                        title={t("metals.gold")}
                        status={status}
                    />
                    <RateTable
                        handleEdit={handleEditSilver}
                        dataBody={dataSilver}
                        title={t("metals.silver")}
                        status={status}
                    />
                </div>
                <div className={classes.rateTableWrapper}>
                    <p className={classes.rateTableTitleBottom}>{t("seller_rate.the_world_spot_price")}</p>
                    <GlobalRateTable
                        dataBody={dataGlobal}
                        title={t("metals.gold")}
                        status={status}
                    />
                </div>
            </div>
            <div className={classes.btnWrap}>
                <CButton onPress={handleCancel} title={t("project.cancel")} borderWidth={1}/>
                <CButton onPress={handleSave} title={t("project.save")} backgroundColor={colors.blue}/>
            </div>
            <UnderHeader title={t("seller_rate.statistics")}/>

            {renderChart}
        </div>
    )
}

export default Rate

